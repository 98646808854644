import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";
import ParallaxContainer from "../components/parallax-container";

export const pageGROQ = `*[_id == $id]
`;

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
      slug {
        current
      }
      _rawBody
    }
  }
`;

const PageTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;
  const pageBody = page._rawBody
    ? page._rawBody
    : page.body
    ? page.body
    : undefined;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      <SEO
        title={page.title}
        description={page.description}
        keywords={page.keywords}
      />
      <main id="main" className="site-main" role="main">
        <div>
          {page.parallaxImages && page.parallaxImages.length > 0 && (
            <div className="fullWidth">
              <ParallaxContainer images={page.parallaxImages} />
              <div id="homeBanner">
                <span>{page.title}</span>
              </div>
            </div>
          )}
          {(!page.parallaxImages || page.parallaxImages.length === 0) && (
            <header className="entry-header wp1220">
              <h1 className="entry-title">{page.title}</h1>
            </header>
          )}
          <div className="wp1220">
            {pageBody && <BlockContent blocks={pageBody} />}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default PageTemplate;
